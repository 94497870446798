
import { useStore } from "vuex";
import { defineComponent, onMounted, computed, ref, onBeforeMount, getCurrentInstance } from "vue";
import { themeName, demo } from "@/core/helpers/documentation";
import QuotesService from "@/core/services/car/QuotesService";
// import LeadDriverDetailsListView from "@/components/partials/leaddriverdetails/Lead-DriverDetails-List.vue";
import LeadDriverDetailsView from "@/components/partials/leaddriverdetails/Lead-DriverDetiails-View.vue";
interface QuotesBodyParam {
  customer_id: number;
  lead_id: number;
}
export default defineComponent({
  name: "ldd-explore",
  components: {
    // QuotesAction,
    // LeadDriverDetailsListView,
    LeadDriverDetailsView,
  },
  props: {
    leadDetails: Object,
    leadDriverDetails: Array,
    customer: Object,
    leadList: Array,
    editObj: Object,
  },
  data() {
    return {
      //  emirates:['Abu Dhabi','Ajman','Dubai','Fujairah','Ras Al Khaimah','Sharjah','Umm Al Quwain'],
    };
  },
  setup(props) {
    const store = useStore();
    let isActive = false;
    let getEditObj = ref<any>();
    // const sendQuote = (quote_id) => {
    //   QuotesService.sendQuote({"quote_id":quote_id});
    // }
    const driverDetailsId = ref(props.leadDriverDetails);
    let key = ref<any>();

    // console.log(driverDetailsId.value);
    let quotesBodyParam = {} as QuotesBodyParam;
    quotesBodyParam = {
      customer_id: -1,
      lead_id: -1,
    };

   

    const getDriverDetailsList = (
      customer_id,
      lead_id = -1,
      driver_details_id
    ) => {
      Promise.all([
        QuotesService.fetchQuote({
          driver_details_id: driver_details_id,
        }),
      ]).then((data) => {
        getEditObj = computed(() => {
          return store.getters.getEditQuote;
        });
        key.value = driver_details_id;
      });
      quotesBodyParam.customer_id = customer_id;
      quotesBodyParam.lead_id = lead_id;
      QuotesService.getDriverDetailsList(quotesBodyParam);
    };
    const showQuotesDom = computed(() => {
      return store.getters.getQuotesDomStatus;
    });

    //  setInterval(function () {
    //  });
    getEditObj = computed(() => {
      return store.getters.getEditQuote;
    });

    const nameCount = (a: any, b: any, c: any, d: any) => {
      return a.length + b.length + c.length + d.length;
    };

    const list = computed(() => {
      const data =  props.leadList
      const quickLead = [2, 10]
      // return data?.filter((lead: any) => lead.lead_status_id != 2);
      return data?.map((val: any) => ({
          ...val,
          is_disable: !quickLead.includes(val.lead_status_id) ? false : true
        }))
    })

    const firstList:any = computed(() => {
      const data =  props.leadList
      const quickLead = [2, 10]
      return data?.find((val:any) => !quickLead.includes(val.lead_status_id))
    })

    const inst = getCurrentInstance()
    const firstClick = () => {
      if(firstList.value) {
        setTimeout(() => {
          const listRef:any = inst?.refs[`list_ref_${firstList.value.id}`]
            listRef[0].click()
        }, 500);
        
      }
    }
    
    const closeModal = (e) => {
      if(e.which === 27) {
        closeRef.value.click()
      }
    }

    const closeRef = ref()

    onBeforeMount(() => {
      window.addEventListener('keyup', closeModal);
    })

    return {
      firstClick,
      firstList,
      closeModal,
      closeRef,
      list,
      nameCount,
      isActive,
      getDriverDetailsList,
      showQuotesDom,
      getEditObj,
      key,
    };
  },
});
