
import { defineComponent, ref, computed, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import InvoiceService from "@/core/services/car/InvoiceService";
import MasterService from "@/core/services/car/MasterService";
import InvoiceAction from "@/components/dropdown/car/InvoiceAction.vue";
import AIExplore from "@/components/invoice/Add-Invoice.vue";
import LocalStorageServices from "@/core/services/LocalStorageServices";
import Invoice from "@/models/car/IInvoiceList";
import ViewDocumentsModal from "@/components/modals/forms/ViewDocumentsModal.vue";
import UploadInvoiceDocument from "@/components/modals/forms/UploadInvoiceDocument.vue";
import { onBeforeRouteLeave } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import ShowQuoteRefNo from "@/components/reusables/showQuoteRefNo.vue";
import { customFunction, roles } from "@/store/stateless/store";
import mainSocket from "./../../../main-socket";

interface InvoiceBodyParam {
  per_page: string;
  agent: string;
  payment_type: number;
  is_amend: number | string;
  keyword: string;
  start_date: string;
  end_date: string;
  status: string;
  is_manual_order: any;
  is_discrepancy: number | null;
}
// interface TransferLeadsParam {
//   customer_ids: [];
//   agent_id: string;
//   notes: string;
// }

export default defineComponent({
  name: "invoice-list",
  components: {
    InvoiceAction,
    AIExplore,
    ViewDocumentsModal,
    UploadInvoiceDocument,
    ShowQuoteRefNo
  },
  data() {
    return {
      shortcuts: [
        {
          text: "Last week",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            return [start, end];
          })()
        },
        {
          text: "Last month",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            return [start, end];
          })()
        },
        {
          text: "Last 3 months",
          value: (() => {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            return [start, end];
          })()
        }
      ],
      value1: "",
      // value2: "",
      invoiceStatus: ["-", "Pending", "Completed", "Failed"]
    };
  },
  setup() {
    // let customers_obj = ref([]);
    let responseResult = ref<any>({});
    responseResult.value.success = false;
    responseResult.value.failed = false;
    responseResult.value.execute = false;
    const store = useStore();
    let currentInvoiceId = ref<any>();
    let invoiceList = ref<Invoice>();
    let invoiceListCount = ref({});
    let activeLink = ref("All");
    let paymentTypes = ref<any>();
    let userObj: any = {};
    let userStr: any = {};
    let bodyParameters = {} as InvoiceBodyParam;

    bodyParameters = {
      per_page: "50",
      agent: "mine",
      payment_type: -1,
      is_amend: -1,
      keyword: "",
      start_date: "",
      end_date: "",
      status: "2",
      is_manual_order: null,
      is_discrepancy: null
    };

    userStr = LocalStorageServices.getUser();
    userObj = JSON.parse(userStr);
    const permissions = computed(() => {
      return store.state.AuthModule.aclPermission;
    });
    onMounted(() => {
      if(!roles.approvedInvoice.includes(roles.userId())) window.location.href = '/#/404'
      
      
      MenuComponent.reinitialization();
      console.log(process.env.IS_ADMIN);
      setCurrentPageBreadcrumbs("Approved Invoices", ["Invoice"]);
      // API Action to get customers list

      bodyParameters.keyword = "";
      bodyParameters.agent = [1, 9, 15].includes(userObj.role_id) ? "all" : "mine";
      getInvoiceList(bodyParameters);
      Promise.all([MasterService.getPaymentTypes()]).then((data) => {
        if (permissions.value.is_sales_agent || permissions.value.is_manager || permissions.value.is_team_leader) {
          const allData = {
            label: "All Transaction Type",
            value: "mine"
          };
          selectedPaymentType.value = "mine";
          let result = store.getters.getPaymentTypeList;
          result.unshift(allData);
          paymentTypes.value = result;
        }
        if (
          permissions.value.is_admin ||
          permissions.value.is_accountant ||
          permissions.value.is_manager || permissions.value.is_qca || permissions.value.is_om
        ) {
          const allData = {
            label: "All Transaction Type",
            value: "all"
          };
          selectedPaymentType.value = "all";
          let result = store.getters.getPaymentTypeList;
          result.unshift(allData);
          paymentTypes.value = result;
        }
      });

      mainSocket
        .channel("policy-channel")
        .listen("CarPolicyCountSocketEvent", (response) => {
          // console.log("policy-channel", response);
          if (response.policy_status) {
            getInvoiceList(bodyParameters, false);
          }
        });

      // if (localStorage.getItem("invoiceReloaded")) {
      //   // The page was just reloaded. Clear the value from local storage
      //   // so that it will reload the next time this page is visited.
      //   localStorage.removeItem("invoiceReloaded");
      // } else {
      //   // Set a flag so that we know not to reload the page twice.
      //   localStorage.setItem("invoiceReloaded", "1");
      //   location.reload();
      // }
    });

    let loadApprovalInformation = computed(() => {
      return store.getters.getApprovalListingDom;
    });

    const runInvoiceScheduler = () => {
      responseResult.value.execute = true;
      Promise.all([InvoiceService.runInvoiceScheduler()]).then((data) => {
        //
        console.log(data);
        if (data.length > 0) {
          responseResult.value = data[0];
        } else {
          responseResult.value.failed = true;
        }

        console.log("2");
      });
    };
    const getPaginationUrl = (index) => {
      // let pagenationUrl = index.split("=");
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.keyword = search.value;
      // InvoiceService.getInvoiceList(bodyParameters, pagenationUrl[1]);
      Promise.all([
        InvoiceService.getInvoiceList(bodyParameters, currentPage.value)
      ]).then((data) => {
        invoiceList.value = store.getters.getInviceList;
      });
    };
    const onPerPageChange = (event) => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      bodyParameters.per_page = event.target.value;
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };

    const getInvoiceListFor = (listFor) => {
      bodyParameters.agent = listFor;
      bodyParameters.keyword = "";
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
      // CustomerService.getCustomerStatusCount(store,bodyParameters);
      // CustomerService.getDueDateTaskCount(store,bodyParameters);
    };
    const getPaymentTypeFor = (listFor) => {
      if (listFor.target.value === "all" || listFor.target.value === "mine") {
        bodyParameters.agent = listFor.target.value;
        bodyParameters.payment_type = -1;
        // bodyParameters.keyword = "";
      } else {
        bodyParameters.payment_type = listFor.target.value;
        // bodyParameters.keyword = "";
      }
      getInvoiceList(bodyParameters);
    };
    const getAmendInvoiceListFor = (listFor) => {
      activeLink.value = listFor;
      isActivePremium.value = false;
      bodyParameters.is_discrepancy = listFor == 0 ? 0 : null;
      bodyParameters.is_amend = listFor;
      // bodyParameters.keyword = "";
      bodyParameters.is_manual_order = null;

      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };
    const getAllInvoiceListFor = () => {
      bodyParameters = {
        per_page: "50",
        agent: "mine",
        payment_type: -1,
        is_amend: -1,
        keyword: search.value,
        start_date: "",
        end_date: "",
        status: "2",
        is_manual_order: null,
        is_discrepancy: null
      };
      // bodyParameters.keyword = "";
      activeLink.value = 'All'
      bodyParameters.agent = [1, 9].includes(userObj.role_id) ? "all" : "mine";
      getInvoiceList(bodyParameters);
    };
    const value2 = ref([]);

    const getInvoiceListPeriod = (listPeriod) => {
      if(listPeriod) {
        var startTS = new Date(listPeriod[0]);
      var endTS = new Date(listPeriod[1]);
      bodyParameters.start_date =
        startTS.getFullYear() +
        "-" +
        (startTS.getMonth() + 1) +
        "-" +
        startTS.getDate();
      bodyParameters.end_date =
        endTS.getFullYear() +
        "-" +
        (endTS.getMonth() + 1) +
        "-" +
        endTS.getDate();
      } else {
        bodyParameters.start_date = '';
        bodyParameters.end_date = '';
      }
      bodyParameters.keyword = "";
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };

    const search = ref<string>("");
    const searchInvoice = () => {
      // bodyParameters = {
      //   per_page: "50",
      //   agent: "mine",
      //   payment_type: -1,
      //   is_amend: -1,
      //   keyword: "",
      //   start_date: "",
      //   end_date: "",
      //   status: "2",
      //   is_manual_order: null,
      //   is_discrepancy: null
      // };
      bodyParameters.keyword = search.value;
      // value2.value = []
      // activeLink.value = "All";
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };
    const selectedPaymentType = ref("");
    const resetBodyParam = () => {
      activeLink.value = "All";
      value2.value = [];
      search.value = "";
      bodyParameters.per_page = "50";
      bodyParameters.agent = userObj.role_id == 2 ? "mine" : "all";
      bodyParameters.payment_type = -1;
      bodyParameters.is_amend = -1;
      bodyParameters.is_manual_order = null;
      bodyParameters.keyword = "";
      bodyParameters.start_date = "";
      bodyParameters.end_date = "";
      bodyParameters.is_discrepancy = null;
      isActivePremium.value = false;

      if (
        permissions.value.is_sales_agent ||
        permissions.value.is_team_leader
      ) {
        selectedPaymentType.value = "mine";
      }
      if (
        permissions.value.is_admin ||
        permissions.value.is_accountant ||
        permissions.value.is_manager || 
        permissions.value.is_om
      ) {
        selectedPaymentType.value = "all";
      }
      // InvoiceService.getInvoiceList(bodyParameters);
      getInvoiceList(bodyParameters);
    };

    const getInvoiceList = (bodyParameters, reload = true) => {
      // console.log(bodyParameters)
      currentPage.value = 1;
      bodyParameters.reload = reload
      Promise.all([
        InvoiceService.getInvoiceListCount(bodyParameters),
        InvoiceService.getInvoiceList(bodyParameters)
      ]).then((data) => {
        invoiceList.value = store.getters.getInviceList;
        invoiceListCount.value = store.getters.getInvoiceCount;
      });
    };

    const showAction = () => {
      //
    };
    const userPermissions = () => {
      return store.getters.getUserPermission;
    };
    const getPolicyDocuments = (invoice_id) => {
      InvoiceService.getInvoiceDocuments({
        invoice_id: invoice_id
      });
    };

    const uploadReceipt = (invoice_id) => {
      currentInvoiceId.value = invoice_id;
      console.log("invoice_id", currentInvoiceId);
    };

    const getManualOrders = () => {
      activeLink.value = "ManualOrder";
      isActivePremium.value = false;
      bodyParameters.is_manual_order = 1;
      bodyParameters.is_discrepancy = null;
      bodyParameters.is_amend = -1;
      getInvoiceList(bodyParameters);
    };

    const isActivePremium = ref<boolean>(false);

    const getPremiumDescripancy = () => {
      activeLink.value = "Discrepancy";
      isActivePremium.value = true;
      bodyParameters.is_discrepancy = 2;
      bodyParameters.is_manual_order = [0, 1];
      bodyParameters.is_amend = -1;
      getInvoiceList(bodyParameters);
    };

    const paginatioObject = computed(() => {
      return store.getters.getInvoicePaginationObject;
    });

    // onBeforeRouteLeave(() => {
    //   store.commit(Mutations.SET_AI_LOADING, true);
    // });

    const currentPage = ref(1);

    const loading = computed(() => {
      return store.getters.getAiLoading;
    });

    const load = async (row, treeNode: unknown, resolve) => {
      console.log(row);
      let parent = false;
      let isManualOrder = bodyParameters.is_manual_order == "1" ? 1 : 0;
      if (isManualOrder === 1) {
        parent = row.parent_invoice_id === 0 ? false : true;
      } else {
        parent = row.is_amend === 0 ? false : true;
      }
      const result = await InvoiceService.getChildInvoice({
        parent_invoice_id: row.is_amend === 0 ? row.id : row.parent_invoice_id,
        parent,
        invoice_id: row.is_amend === 1 ? row.id : row.parent_invoice_id,
        is_manual_order: isManualOrder,
        status: 2
      });
      resolve(result);
    };

    const openOnlinePaymentInfo = (invoice_id) => {
      InvoiceService.getOnlinePaymentInfo({
        online_invoice_id:invoice_id
      }).then((data) => {
        InvoiceService.setOnlinePaymentInfoModal(true);
      });
      
    };

    return {
      roles,
      openOnlinePaymentInfo,
      customFunction,
      load,
      loading,
      value2,
      isActivePremium,
      getPremiumDescripancy,
      paginatioObject,
      currentPage,
      selectedPaymentType,
      permissions,
      loadApprovalInformation,
      runInvoiceScheduler,
      getPaymentTypeFor,
      getAmendInvoiceListFor,
      showAction,
      search,
      searchInvoice,
      onPerPageChange,
      getPaginationUrl,
      getInvoiceListFor,
      bodyParameters,
      getInvoiceListPeriod,
      resetBodyParam,
      responseResult,
      userPermissions,
      getPolicyDocuments,
      invoiceList,
      currentInvoiceId,
      uploadReceipt,
      paymentTypes,
      getInvoiceList,
      getManualOrders,
      getAllInvoiceListFor,
      activeLink,
      invoiceListCount
    };
  }
});
